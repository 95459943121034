.drag-lists[data-v-740ef6e8] {
  flex: 1;
  padding-bottom: var(--spacing-20);
}
.drag-lists .ecs-tree-view-list[data-v-740ef6e8] {
    flex: 1;
}
.drag-list-wrap[data-v-740ef6e8] {
  flex: 1;
}
.drag-list-wrap > .ecs-tree-view-list[data-v-740ef6e8] {
    width: 100%;
    flex: 0;
}
.drag-list[data-v-740ef6e8] {
  height: 100%;
}
.drag-list > div[data-v-740ef6e8] {
    border-radius: var(--border-radius-small);
    cursor: grab;
}
.drag-list [draggable=true][data-v-740ef6e8] {
    background: #FFF;
    cursor: grabbing;
}
.drag-list .sortable-ghost[data-v-740ef6e8] {
    background: var(--color-blue-3);
    color: var(--color-blue-15);
    opacity: .6;
}
.search-column[data-v-740ef6e8] > * {
  flex: 1;
}
.no-results[data-v-740ef6e8] {
  flex: 1;
}
